<template>
  <div id="app" class="body-m scroll-pt-[65px] md:scroll-pt-[93px]">
    <Notification />
    <!-- icons for audio + video players -->
    <div class="hidden">
      <IconPlay id="icon-play" width="18" height="18" />
      <IconPause id="icon-pause" width="18" height="18" />
      <IconEnterFullscreen id="icon-enter-fullscreen" width="18" height="18" />
      <IconExitFullscreen id="icon-exit-fullscreen" width="18" height="18" />
      <IconMute id="icon-volume" width="18" height="18" />
      <IconUnmute id="icon-muted" width="18" height="18" />
    </div>
    <template v-if="isMm()">
      <LayoutMmHeader ref="headerMm" />
    </template>
    <template v-else>
      <LayoutHeader ref="header" />
    </template>

    <template v-if="config.public.site.ads && showBannerAd">
      <AdsConceptAd
        :id="`${config.public.site.ads.placementPrefix}-lb1`"
        full-width
        class="hidden lg:block"
        :class="{
          'min-h-[150px]': isSite('altinget_no'),
        }"
      />
      <AdsConceptAd
        :id="`${config.public.site.ads.placementPrefix}-mob1`"
        class="lg:hidden block"
        full-width
      />
    </template>
    <div class="container my-2" v-if="config.public.site.allowPurchase">
      <SubscriptionsNotificationBanner />
    </div>
    <div id="page">
      <slot></slot>
    </div>
    <CommonAudioPlayer class="no-print" />
    <LayoutFooterMenu v-if="!isMm()" class="no-print" />
    <LayoutFooterMM v-if="isMm()" class="no-print" />
    <LayoutFooter v-else class="no-print" />
    <AtsTrialController
      v-if="trialModalState.open"
      :papers="trialModalState?.papers ?? []"
      :skip-start-trial="trialModalState?.skipStartTrial"
      :return-url="trialModalState?.returnUrl"
      @modal-close="trialModalState.open = false"
    />
    <AdsDebugAds />
  </div>
</template>

<script setup lang="ts">
import IconPlay from '~/assets/icons/play.svg?component'
import IconPause from '~/assets/icons/pause.svg?component'
import IconEnterFullscreen from '~/assets/icons/enter-fullscreen.svg?component'
import IconExitFullscreen from '~/assets/icons/exit-fullscreen.svg?component'
import IconMute from '~/assets/icons/mute.svg?component'
import IconUnmute from '~/assets/icons/muted.svg?component'

const nuxtApp = useNuxtApp()
const route = useRoute()
const config = useRuntimeConfig()

const trialModalState = useTrialModalState()

const indexStore = useIndexStore()

await useAsyncData(async () => {
  if (!isMm()) return null
  const tempTheme = (
    await nuxtApp.$api.content.mmThemes({
      count: 1,
      offset: 0,
    })
  )?.[0]
  if (!tempTheme) return null
  indexStore.setWeeklyTheme(tempTheme)
  indexStore.setWeeklyThemeColor(tempTheme?.Color)
  return tempTheme
})

useHead({
  link: [
    {
      rel: 'canonical',
      href: `https://${config.public.site.url}${route.path}`,
    },
  ],
})

const showBannerAd = computed(() => {
  const activeRouteName = route.name ? route.name.toString() : ''

  return (
    config.public.site.adProvider === 'Concept' &&
    ![
      'sponsored',
      'sponsored_id',
      'sponsored_paper_id',

      'newsletter',
      'elections',
      'election',
      'electionTest',
      'electionTestResult',
      'electionProfile',
      'electionArticle',
      'electionBallot',
      'search',
      'searchwords',
      'login',
      'prices',
      'pricing',
      'cookieinfo',
      'terms',
      'account_profile',
      'account_newsletter',
      'saved_articles',
      'account_loginsecurity',
      'copyright',
      'subscription',
      'political-speeches-index',
      'comments-index',
      'decisionchain',
      'decisionchain_id',
    ].includes(activeRouteName)
  )
})
</script>

<style lang="scss">
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
