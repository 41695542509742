<template>
  <CommonLoading v-if="loading" />
  <div v-else>
    <form
      class="w-64 ml-auto mr-auto grid gap-4"
      @submit.prevent="submit"
      id="form_trial_signup_create_user"
    >
      <i18n-t
        tag="h2"
        class="font-sans font-medium text-center text-sm"
        keypath="trialSignup.stepXOfY"
        scope="global"
      >
        <template #x>1</template>
        <template #y>2</template>
      </i18n-t>
      <FormAlert
        :type="alertType"
        :message="
          alertMessageRaw &&
          [
            'atsLogin.error.disallowedDomainRiksdagen',
            'A user with that private or work email exists already',
          ].includes(alertMessageRaw)
            ? ''
            : alertMessage
        "
      >
        <i18n-t
          v-if="
            alertMessageRaw ===
            'A user with that private or work email exists already'
          "
          keypath="A user with that private or work email exists already"
          scope="global"
        >
          <template #oppositeSiteName>
            {{ isMm() ? 'Altinget' : 'Mandag Morgen' }}
          </template>
          <template #logInToContinue>
            <span
              role="button"
              class="underline cursor-pointer text-blue"
              @click.prevent.stop="$emit('click-login')"
              >{{ $t('logInToContinue') }}</span
            >
          </template>
          <template #resetYourPasswordHere>
            <NuxtLink
              :to="{
                name: 'userforgotpassword',
              }"
              :external="true"
              class="underline text-blue"
              >{{ $t('resetYourPasswordHere') }}</NuxtLink
            >
          </template>
        </i18n-t>
        <i18n-t
          v-if="alertMessageRaw === 'atsLogin.error.disallowedDomainRiksdagen'"
          keypath="atsLogin.error.disallowedDomainRiksdagen"
          scope="global"
        >
          <template #loginRiksdagen>
            <a
              class="underline text-blue"
              href="/usercreate?email=9IlxiVgs0mzR9WMmQGjB7w%3D%3D"
              >HÄR</a
            >
          </template>
        </i18n-t>
      </FormAlert>
      <FormAlert v-if="isSSOEmail" type="info">
        <span
          class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
          v-html="$t('sso.YourEmailIsOnAnSSOClient', { url: ssoUrl })"
        ></span>
      </FormAlert>
      <FormInput
        :modelValue="email"
        type="email"
        required
        placeholder="enterEmail"
        @blur="checkSSO"
        @update:modelValue="$emit('update:email', $event)"
      />

      <FormPassword
        :modelValue="password"
        placeholder="choosePassword"
        :title="$t('passwordRequirements')"
        :allow-toggle="true"
        @update:modelValue="$emit('update:password', $event)"
      />

      <input
        type="submit"
        class="w-full cursor-pointer py-3 px-4 text-white article-label !font-medium"
        :value="$t('Next')"
        :class="
          isMm()
            ? 'rounded-md bg-black transform translate-y-px'
            : 'rounded-full bg-blue hover:bg-denimblue'
        "
        @click="dataLayer.trial.createUser()"
      />
    </form>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { FetchError } from 'ofetch'
import { SSOProvider } from '~/typesManual/sso'
const config = useRuntimeConfig()

const nuxtApp = useNuxtApp()
const dataLayer = useDataLayer()
const userStore = useUserStore()

const props = defineProps<{
  email: string
  password: string
}>()

const emit = defineEmits<{
  (event: 'update:email', value: string): void
  (event: 'update:password', value: string): void
  (event: 'click-login'): void
}>()

const loading = ref(false)

async function submit() {
  if (isDisallowedAtsDomain(props.email)) {
    setAlert('atsLogin.error.disallowedDomainRiksdagen')
    return false
  }

  loading.value = true
  try {
    const user = await nuxtApp.$api.user.createUser({
      workEmail: props.email,
      password: props.password,
    })

    userStore.setUserCookies(user.autoLogin ?? '')

    if (user.autoLogin) {
      await userStore.loadUserFromAutologin({
        autologin: user.autoLogin,
      })
    }
  } catch (error) {
    const e = error as FetchError
    setAlert(e?.response?._data)
  }
  loading.value = false
}

// Single Sign-On checks
const ssoProvider = ref<SSOProvider | null>(null)
const isSSOEmail = computed(() => Boolean(ssoProvider.value))

const ssoUrl = computed(() => {
  if (import.meta.server || !ssoProvider.value) return ''

  const isIdentity = ssoProvider.value.providerName === 'IdentityEntraId'

  const url = new URL(
    isIdentity
      ? `${window.location.origin}/api/auth/sign-in-sso`
      : ssoProvider.value.providerUrl
  )
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})

async function checkSSO() {
  if (props.email && config.public.site.allowSingleSignOn) {
    ssoProvider.value = await nuxtApp.$api.user.getSsoProviderByEmail(
      props.email
    )
  } else {
    ssoProvider.value = null
  }
}

function isDisallowedAtsDomain(email: string) {
  return email.includes('@riksdagen.se')
}

// Error handling
const { setAlert, alertType, alertMessage, alertMessageRaw } = useAlert()
</script>
