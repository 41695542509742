<template>
  <header
    ref="headerMm"
    class="sticky bg-white top-0 transform-gpu transition-all duration-300 z-[9999] print:!hidden border-gray-300 border-b"
    :class="{ 'md:border-none': !headerIsPinned }"
  >
    <div
      class="container flex items-center justify-between mx-auto relative z-10 pointer-events-auto"
      :class="headerIsPinned ? 'py-1 md:py-2 ' : 'py-3 md:py-7'"
      ,
    >
      <div>
        <div
          id="toggle-header-menu"
          class="p-3 -ml-3 md:-mr-3 cursor-pointer"
          @click="toggle('burgermenu')"
        >
          <div
            class="toggle transition-opacity duration-100"
            :class="{ open: activeMenu === 'burgermenu' }"
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <LayoutMmBurgerMenu
          :open="activeMenu === 'burgermenu'"
          @close-menu="toggle('burgermenu')"
          :class="{
            '-translate-y-[17px] md:-translate-y-[94px]': headerIsPinned,
          }"
        />
      </div>
      <div
        class="list-title-m md:ml-auto transition-all duration-300 absolute left-1/2 transform-gpu -translate-x-1/2 w-[197px] h-[24px] md:w-[296px] md:h-[36px]"
        :class="{ 'scale-[0.7]': headerIsPinned }"
      >
        <NuxtLink :to="{ name: 'frontpage' }">
          <img src="/MM_Logo.svg" />
        </NuxtLink>
      </div>
      <div class="flex flex-col items-center">
        <LayoutUser @click="toggle('login')" />
        <span
          v-if="!userStore.user"
          class="body-xs md:hidden"
          :class="{
            hidden: headerIsPinned,
          }"
          >Log ind</span
        >
      </div>
    </div>

    <!-- MM sub Nav -->
    <div class="hidden md:flex">
      <LayoutMmNavbar
        :class="{
          hidden: headerIsPinned,
        }"
      />
    </div>
    <div
      v-if="!userStore.user && !isSubscriptionRoute"
      class="relative border-t border-gray-300 pointer-events-auto text-center container py-2 md:hidden"
      :class="{
        hidden: headerIsPinned,
      }"
    >
      <NuxtLink
        id="UserCreateProfileMobileMm"
        :to="{ name: 'subscription' }"
        class="button-l px-4 rounded-sm h-[30px] bg-blue text-white border-none whitespace-nowrap items-center transition-colors inline-flex duration-100 select-none shrink-0 justify-center w-full"
      >
        {{ $t('CreateProfile') }}
      </NuxtLink>
    </div>
  </header>
</template>

<script setup lang="ts">
const route = useRoute()
const activeMenu = ref()
const headerIsPinned = ref(false)
const headerMm = ref<HTMLElement>()
const userStore = useUserStore()
const toggle = (menu: string) => {
  activeMenu.value = activeMenu.value === menu ? '' : menu
}

const isSubscriptionRoute = computed(() => {
  if (!route.name) return
  return [
    'subscription',
    'subscription_type',
    'subscription_type_information',
  ].includes(route.name.toString())
})

function handleScroll() {
  headerIsPinned.value = window.scrollY > 0
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

watch(
  () => route.path,
  () => {
    activeMenu.value = ['']
  }
)
</script>
